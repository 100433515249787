import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { Text as TextUI, jsx } from 'theme-ui'

const Text = React.forwardRef(({ textStyle, children, styles }, ref) => {
  return (
    <TextUI ref={ref} sx={{ variant: `text.${textStyle}`, ...styles }}>
      {children}
    </TextUI>
  )
})

export default Text
